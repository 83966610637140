@import "constants";

.wrapper3d {
  position: relative;
  width: 100%;
  padding-top: 66%;
}

.canvas3d {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: $img-border-radius;
  border: 1px solid lightgray;
}

.ar-btn {
  position: absolute;
  font-size: 20px;
  top: 0;
  margin: 5px;

  display: block;
  background: white;
  border-radius: .25em;
  border: 1px solid lightgray;
}