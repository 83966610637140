ol.ieee {
  counter-reset: list;
}
ol.ieee > li {
  list-style: none;
  position: relative;
  padding-left: 1em;
}
ol.ieee > li:before {
  content: "[" counter(list) "] ";
  counter-increment: list;
  position: absolute;
  left: 0;
  width: 0;
  direction: rtl;
  background-color: rgb(161, 194, 194);
  font-variant-numeric: tabular-nums;
}